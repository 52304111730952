import React, { useState } from "react";
import "./IRA.css";

import CTA from "../CTA/CTA";

const IRA = () => {
  // State to keep track of the active content type
  const [activeType, setActiveType] = useState("Institutional Investors");

  // Function to update the active content type
  const handleToggle = (type) => {
    setActiveType(type);
  };

  return (
    <div className="IRA" id="investors">
      <div className="IRA-toggle">
        <a onClick={() => handleToggle("Institutional Investors")}>
          <button>Institutional Investors</button>
        </a>
        <a onClick={() => handleToggle("Family Office")}>
          <button>Family Offices</button>
        </a>
        <a onClick={() => handleToggle("Funds of Funds")}>
          <button>Funds of Funds</button>
        </a>
      </div>

      <div className="IRA-content">
        {activeType === "Institutional Investors" && (
          <div className="container">
            <div className="heading">
              <h1>Institutional Investors</h1>
            </div>
            <div className="about-container">
              <div className="container-box">
                <div className="text">
                  <p>
                    Leveraging the same databases used by prominent investment
                    bankers, venture capitalists and private equity firms around
                    the world, we've created our own 3rd party tools in order to
                    scale the efforts of these traditional firms 10X. This leads
                    to more high quality meetings with Institutions beyond just
                    our networks and relationships built with top firms around
                    the world from A16Z to Blackrock.
                    <br /> <br />
                    Our strategy involves direct outreach to 5,000-20,000
                    investors per campaign, equating to over 100 outreaches
                    daily. Our proficient back end team is comprised of
                    investment bankers, former VC professionals and masters
                    graduates of finance.
                  </p>
                </div>
                <CTA />
              </div>
            </div>
          </div>
        )}

        {activeType === "Family Office" && (
          <div className="container">
            <div className="heading">
              <h1>Family Offices</h1>
            </div>
            <div className="about-container">
              <div className="container-box">
                <div className="text">
                  <p>
                    Building on our extensive database of over 4 million global
                    institutional investors, we've specialized in engaging with
                    family offices. These high-net-worth entities manage
                    extensive assets and are ideal for large-scale and strategic
                    investments, aligning with their long-term goals for wealth
                    growth and preservation. Our precise outreach caters to
                    their unique investment criteria, ensuring effective
                    connections.
                  </p>
                </div>
                <CTA />
              </div>
            </div>
          </div>
        )}

        {activeType === "Funds of Funds" && (
          <div className="container">
            <div className="heading">
              <h1>Funds of Funds</h1>
            </div>
            <div className="about-container">
              <div className="container-box">
                <div className="text">
                  <p>
                  We also focus on connecting with Funds of Funds. These entities aggregate capital from various sources to invest in a diversified portfolio of funds, making them key players in spreading risk and accessing a broad spectrum of investment opportunities. Our targeted approach efficiently aligns with their diversified investment strategies, facilitating impactful engagements and partnerships.


                  </p>
                </div>
                <CTA />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default IRA;
